import React, { useState } from "react";
import styled from "styled-components";
import ContactImage from "../assets/images/contactspace.jpg";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => console.log("Form successfully submitted"))
      .catch((error) => alert(error));

    navigate("/success");
  };

  return (
    <ContactsWrapper>
      <ContactsContent>
        <ContactText>
          <TextSectionOne>
            Kontakta oss för en kostnadsfri digital konsultation där vi går
            igenom vad du behöver för just ditt företag och hur vi kan hjälpa
            dig
          </TextSectionOne>
          <TextSectionTwo>
            Vi är redo att guida dig genom digitala möjligheter skräddarsydda
            för ditt företag. Oavsett om det är en modern webbplats, effektiv
            webbutveckling eller strategiskt innehåll, hjälper vi dig att forma
            en framgångsrik närvaro online. Boka din kostnadsfria konsultation idag och låt
            oss tillsammans skapa digital magi!
          </TextSectionTwo>
        </ContactText>

        <FormSection>
          <FormContainer>
            <FormHeader>Välkommen att kontakta oss redan idag!</FormHeader>
            <FormText>Har du frågor eller funderingar kring ditt projekt kan vi samarbeta för att ta fram den bästa lösningen, skicka förfrågan eller mejla oss så för vi en dialog.</FormText>
            <Form
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"
              action="/"
              onSubmit={handleSubmit}
            >
              <Input type="hidden" name="form-name" value="contact" />

              <Label htmlFor="name">Namn</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />

              <Label htmlFor="email">Epost</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <Label htmlFor="message">Meddelande</Label>
              <TextArea
                id="message"
                name="message"
                rows="4"
                cols="50"
                placeholder="Skriv ditt meddelande här..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></TextArea>
              <div data-netlify-recaptcha="true"></div>
              <SubmitButton type="submit" value="Skicka" />
            </Form>
          </FormContainer>

          <FormImageSection>
            <img src={ContactImage} alt="computer screen" />
          </FormImageSection>
        </FormSection>
      </ContactsContent>
    </ContactsWrapper>
  );
}

const ContactsWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const ContactsContent = styled.div`
  background: linear-gradient(
    3deg,
    rgba(187, 170, 211, 1) 0%,
    rgba(148, 208, 233, 0.6) 100%
  );
  width: 100%;
  height: auto;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 615px) {
    padding-top: 150px;
  }
`;

const ContactText = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(137, 138, 166, 0.2);

  @media screen and (max-width: 615px) {
    height: 60vh;
  }

  @media screen and (max-width: 321px) {
    height: 70vh;
  }

`;

const TextSectionOne = styled.p`
  width: 60%;
  height: auto;
  font-size: 1.3rem;
  font-family: 'Sora', sans-serif;

  @media screen and (max-width: 769px) {
    width: 80%;
  }

  @media screen and (max-width: 615px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 321px) {
    width: 90%;
  }
`;

const TextSectionTwo = styled.p`
  width: 60%;
  height: auto;
  font-size: 1rem;
  margin-top: 15px;
  color: #453f3d;

  @media screen and (max-width: 769px) {
    width: 80%;
  }

  @media screen and (max-width: 321px) {
    width: 90%;
    font-size: 1rem;
  }
`;

// Form section styles

const FormSection = styled.div`
  color: #453f3d;
  width: 70%;
  height: auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;

  @media screen and (max-width: 769px) {
    width: 90%;
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 769px) {
    width: 80%;
    flex-direction: column;
  }

  @media screen and (max-width: 426px) {
    width: 90%;
  }
  @media screen and (max-width: 321px) {
    width: 100%;
  }
`;

const FormHeader = styled.h1`
  color: black;
  font-size: 1.5rem;
  align-self: start;
  margin-bottom: 20px;
  font-family: 'Sora', sans-serif;
`;

const FormText = styled.p`
    margin-bottom: 20px;
`

const Form = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.invalid {
    border: 2px solid red;
  }
`;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
  align-self: start;
  position: relative;

  &::after {
    content: "*";
    color: red;
    margin-left: 4px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allows vertical resizing */
`;

const SubmitButton = styled.input`
  width: 30%;
  padding: 12px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: start;
  transition: background-color 0.3s ease;
  font-family: 'Sora', sans-serif;

  &:hover {
    background-color: #45a049;
  }
`;

const FormImageSection = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
  }

  @media screen and (max-width: 769px) {
    width: 80%;
    margin-top: 50px;

    img {
      width: 90%;
    }
  }

  @media screen and (max-width: 426px) {
    width: 100%;

    img {
      width: 100%;
    }
  }

`;

