// packagePrices.js

export const packagePrices = [
    {
      title: "Single Page Paket",
      description:
        "Single-page-paketet är lämpligt för dig som vill ha en enkel hemsida som presenterar all viktig information på samma sida som samtidigt enkelt delar din historia eller marknadsför ditt evenemang.",
      features: [
        "Webbplats med en webbsida",
        "Unik webbdesign skapad kring ditt varumärke",
        "Responsiv och användarvänlig",
        "SSL-säkert & med cookiebanner",
        "Kontaktformulär",
        "Grundläggande SEO-optimering",
        "Länkning till sociala medier",
        "Instruktioner på hur du själv kan uppdatera bild och text",
      ],
      price: "12000",
    },
    {
      title: "Multi Page Paket",
      description:
        "Multi-Page-paket är skräddarsytt för företag, professionella tjänsteleverantörer och organisationer som behöver en omfattande online närvaro. Med flera sidor kan du presentera ditt företag, tjänster och produkter i detalj.",
      features: [
        "Webbplats med flera webbsidor",
        "Unik webbdesign skapad kring ditt varumärke",
        "Responsiv och användarvänlig",
        "SSL-säkert & med cookiebanner",
        "Kontaktformulär",
        "Grundläggande SEO-optimering",
        "Länkning till sociala medier",
        "Instruktioner på hur du själv kan uppdatera bild och text",
      ],
      price: "14000",
    },
  ];