import React from 'react'
import styled from "styled-components";
import servicesData from '../data/services';

export default function products() {
  return (
    <Container>
      <Services>
        {servicesData.map((service) => (
          <ServiceCard key={service.id}>
            <ServiceImage src={service.image} alt={service.title} />
            <ServiceInfo>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceInfo>
          </ServiceCard>
        ))}
      </Services>
  </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background-color: #F8EDE3;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Services = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;

  @media screen and (max-width: 768px){
    width: 100%;
    gap: 10px;
  }
  
  @media screen and (max-width: 426px){
    width: 100%;
    gap: 10px;
  }
`;

const ServiceCard = styled.div`
  width: 320px;
  height: auto;
  overflow: hidden;
  margin: 50px 0 50px 0;

  @media screen and (max-width: 768px){
    margin: 40px 0 10px 0;
  }
  @media screen and (max-width: 426px){
    margin: 40px 0 20px 0;
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const ServiceInfo = styled.div`
  padding: 15px;

  @media screen and (max-width: 426px){
    padding: 0;
  }
  @media screen and (max-width: 426px){
    padding: 10px;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 5px;
  margin-top: 10px;
  font-family: 'Sora', sans-serif;
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: #555;
`;

// const ProductsWrapper = styled.div`
//   width: 100%;
//   height: auto;
//   display: flex;
//   justify-content: center;
//   background-color: #F8EDE3;
// `

// const Products = styled.div`
//   width: 70%;
//   height: 300px;
//   display: flex;
//   justify-content: space-evenly;
// `
// const ProductItem = styled.div`
//   width: 40%;
// `
