import DesignImg from '../assets/services/design.png'
import UtvecklingImg from '../assets/services/utveckling.png'
import ContentImg from '../assets/services/contentTwo.png'

const servicesData = [
    {
      id: 1,
      title: "Design",
      description: "Vår webbdesign kombinerar estetik och funktionalitet för att skapa en visuell identitet som tydligt kommunicerar ditt företags varumärke. Vi fokuserar på användarupplevelse och moderna designprinciper för att göra din webbplats både tilltalande och användarvänlig.",
      image: DesignImg
    },
    {
      id: 2,
      title: "Utveckling",
      description: "Vi skapar smarta och effektiva lösningar som möter dina digitala behov. Vi använder moderna teknologier för att leverera skräddarsydda webbapplikationer och säkerställer användarvänlighet samt responsitivet genom alla våra projekt. ReactJs och headless CMS är exempel på teknologier som används.",
      image: UtvecklingImg
    },
    {
      id: 2,
      title: "Tilläggstjänster",
      description: "Våra tilläggstjänster erbjuder en heltäckande lösning för att maximera din online närvaro. Från engagerande content writing och underhåll av hemsida till skapande av en professionell grafisk profil, vi har allt täckt. Med vårt fokus på detaljer och kreativitet tar vi din digitala närvaro till nästa nivå. Låt oss hantera de extra stegen, så du kan fokusera på att driva ditt företag framåt.",
      image: ContentImg
    
    }
  ];

  export default servicesData;