import React, { useState } from "react";
import styled from "styled-components";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const Wrapper = styled.div`
  width: 100%;
`;

const QuestionsContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  background-color: #F8EDE3;
  border-radius: 20px;

  
  @media screen and (max-width: 426px) {
    width: 90%;
  }
`;

const Question = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
`;

const QuestionText = styled.strong`
  flex: 1;
`;

const Answer = styled.div`
  padding: 20px;
  background-color: #F8EDE3;
  border-radius: 0 0 20px 20px;
  border-top: 1px solid pink;
  margin-bottom: 5px;
`;

const Icon = styled.div`
  margin-right: 10px;
`;

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper>
      <QuestionsContainer>
        <Question onClick={toggleAnswer}>
          <Icon>{isOpen ? <BsChevronUp /> : <BsChevronDown />}</Icon>
          <QuestionText>{question}</QuestionText>
        </Question>
        {isOpen && <Answer>{answer}</Answer>}
      </QuestionsContainer>
    </Wrapper>
  );
};

export default FAQItem;
