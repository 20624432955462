const faqData = [
    {
      id: 1,
      question: 'Hur kan Studio Meridian hjälpa mig?',
      answer: 'Vår webbutvecklingsbyrå är dedikerad till att skapa anpassade och responsiva webbplatser som uppfyller dina unika behov. Vi erbjuder fullständig tjänst, från koncept till lansering och därefter.'
    },
    {
      id: 2,
      question: 'Vilka typer av webbtjänster erbjuder Studio Meridian?',
      answer: 'Vi erbjuder tjänster inom webbutveckling, alltifrån att skissa fram en unik design till att bygga färdigt den. Vi hjälper dig att ta fram en grafisk profil till ditt märke och content som passar just ditt företag. vi erbjuder efterföljande underhåll och support för att säkerställa att din webbplats förblir säker och fungerar optimalt även efter lanseringen. Så du får tid till annat viktigt!'
    },
    {
      id: 3,
      question: 'Vad skiljer vår webbutvecklingsprocess från andra byråer?',
      answer: 'Vår webbutvecklingsprocess fokuserar på samarbete och transparens. Vi lyssnar på dina behov, skapar skräddarsydda lösningar och håller dig informerad under hela projektet. Vi finns tillgänglig för att besvara alla frågor under hela processen men även därefter.'
    },
    {
      id: 4,
      question: 'Vad finns det för betalsätt?',
      answer: 'Vi fakturerar via Cool Company där vi vid leverans av tjänst, skickar fakturan till dig med hjälp av Cool Companys verktyg.'
    },
    {
      id: 5,
      question: 'Hur lång tid tar det att utveckla en webbplats?',
      answer: 'Utvecklingstiden för en webbplats varierar oftast baserat på projektets omfattning. Processen, inklusive diskussion om dina behov, skissande av design och den faktiska byggfasen, utgör olika steg under projektets utveckling. Tidsåtgången för varje steg varierar beroende på projektets komplexitet och storlek. För att ge en mer exakt tidsram behöver vi en djupare förståelse av de specifika kraven och detaljerna i det individuella projektet.'
    },
    {
      id: 6,
      question: 'Kan ni hjälpa till med webbdesign och användarupplevelse (UX) design?',
      answer: 'Absolut, vi erbjuder helhetslösningar inklusive webbdesign och UX-design för att skapa attraktiva och användarvänliga webbplatser.'
    },
    {
      id: 7,
      question: 'Vad är era priser och betalningsvillkor?',
      answer: 'Våra priser varierar beroende på projektets omfattning. Vi erbjuder skräddarsydda prisplaner och tydliga betalningsvillkor baserat på dina behov. Vi har även tilläggstjänster som ni kan köpa till för att ytterligare anpassa er upplevelse och möta specifika krav eller önskemål. Kontakta oss gärna för att diskutera era behov och få en skräddarsydd offert som passar just er verksamhet.'
    },
    // Add more FAQ items as needed
  ];
  
  export default faqData;
  