import React from 'react'
import styled from 'styled-components';

export default function cookies() {
  return (
    <Wrapper>
        <Content>
        <h1>Vi använder för närvarande endast strikt nödvändiga cookies för att säkerställa att webbplatsen fungerar korrekt. Dessa cookies är avgörande för att tillhandahålla de tjänster du begär och kan inte stängas av i våra system. De lagras vanligtvis endast som svar på åtgärder du utför som utgör en tjänstförfrågan, som att ställa in dina integritetspreferenser eller fylla i formulär.</h1>
        </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  background: linear-gradient(
    3deg,
    rgba(187, 170, 211, 1) 0%,
    rgba(148, 208, 233, 0.5) 100%
  );
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 70%;
  height: auto;
  background-color: rgb(248, 237, 227, 0.5);
  margin: 200px 0 100px;
  padding: 40px 20px;
`;
