const categories = [
    {
      name: "Webbdesign",
      text: "Vi skapar användarvänliga och visuellt tilltalande upplevelser. Med noggrant utformade gränssnitt (UI) och enkel navigering (UX) strävar vi efter att göra varje besök på din webbplats meningsfullt och minnesvärt. Vi anpassar designen för att belysa ditt varumärkes identitet och kommunicera effektivt med din målgrupp."
    },
    {
      name: "Webbutveckling",
      text: "Vi strävar efter att bygga robusta och funktionella webblösningar som möter dina specifika behov med moderna teknologier. Från att optimera prestanda till att leverera en sömlös och användarvänlig upplevelse, tar vi din webbutveckling till nya höjder med fokus på innovation och kvalitet."
    },
    {
      name: "SEO-optimering",
      text: "Optimering av din webbplats för bättre synlighet online med vår effektiva SEO-tjänst. Vi ökar din digitala närvaro genom strategisk optimering, vilket resulterar i ökad webbtrafik och förbättrade sökresultat."
    }
  ];

  export default categories;

  
  //   {
  //     name: "Content Writing",
  //     path: "/content-writing",
  //     text: "Vi skapar engagerande och strategiskt utformat innehåll. Genom att kombinera kreativitet och marknadsföringsinsikter arbetar vi för att berätta din unika historia och övertyga besökarna att engagera sig. Från SEO-optimerat innehåll till övertygande copywriting strävar vi efter att skapa en meningsfull anslutning mellan ditt varumärke och din målgrupp. (Obs tilläggstjänst)"
  //   },
//   {
//     name: "Administration",
//     path: "/administration",
//     text: "Våra administrationstjänster sträcker sig bortom leveransfasen för att säkerställa din webbplats långsiktiga prestanda. Vi tar hand om underhåll och uppdateringar, så du kan fokusera på din verksamhet."
//   },