import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Success = () => {
  return (
    <SuccessWrapper>
      <SuccessContent>
        <SuccessMessage>
          Tack för ditt meddelande. Vi kommer att återkomma till dig så snart
          som möjligt.
        </SuccessMessage>
        <HomeButton to="/">Tillbaka till startsidan</HomeButton>
      </SuccessContent>
    </SuccessWrapper>
  );
};

const SuccessWrapper = styled.div`
  background: linear-gradient(
    3deg,
    rgba(187, 170, 211, 1) 0%,
    rgba(148, 208, 233, 1) 100%
  );
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessContent = styled.div`
  width: 40%;
  text-align: center;

  @media screen and (max-width: 769px) {
    width: 60%;
  }
  @media screen and (max-width: 376px) {
    width: 80%;
  }
`;

const SuccessMessage = styled.h2`
  color: white;
  font-size: 1.5rem;

  @media screen and (max-width: 769px) {
    font-size: 1.3rem;
  }
`;

const HomeButton = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: #453f3d;
  background-color: #9dcadd;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    color: white;
  }
`;

export default Success;
