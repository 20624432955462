import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../assets/logo/logo_cropped.png";

export default function footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoContainer>
          <HeaderLogo to="/">
            <img src={Logo} alt="Logo of Studio Meridian" />
          </HeaderLogo>
        </LogoContainer>
        <FooterLinks>
          <FooterLink to="/">START</FooterLink>
          {/* <FooterLink to="/projects">PROJEKT</FooterLink> */}
          <FooterLink to="/services">TJÄNSTER</FooterLink>
          <FooterLink to="/about">OM OSS</FooterLink>
          <FooterLink to="/contact">KONTAKT</FooterLink>
        </FooterLinks>
        <FooterText>
          {/* <FooterTextItem>kontakt_studiomeridian@outlook.com</FooterTextItem> */}
          <Policy to="/integritetspolicy">
            Integritetspolicy
          </Policy>
          <Policy to="/cookiepolicy">
            Cookiepolicy
          </Policy>
        </FooterText>
      </FooterContent>

      <CompanyNameContainer>
        <CompanyName>Studio Meridian 2023 – Innehar F-skatt</CompanyName>
      </CompanyNameContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  width: 100%;
  height: 45vh;
  background-color: rgb(174, 183, 218);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 426px) {
    height: 60vh;
    padding-top: 20px;
  }
`;

const FooterContent = styled.div`
  width: 70%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 768px) {
    width: 90%;
    height: auto;
    gap: 20px;
    padding-top: 20px;
  }

  @media screen and (max-width: 426px) {
    width: 100%;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;
  }
`;

const LogoContainer = styled.div`
  width: 30%;
  align-self: center;

  @media screen and (max-width: 426px) {
    width: 40%;
  }
`;

const HeaderLogo = styled(Link)`
  cursor: pointer;
  width: 200px;

  @media screen and (max-width: 800px) {
    width: 300px;
  }
`;

const FooterLinks = styled.div`
  width: 30%;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 426px) {
  }
`;

const FooterLink = styled(Link)`
  font-size: 1rem;
  margin: 0 10px 0 10px;
  padding: 0 5px;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Transparent background on hover */
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.3); /* Transparent background on click */
  }

  @media screen and (max-width: 800px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 426px) {
    font-size: 0.8rem;
  }
`;

const FooterText = styled.div`
  width: 30%;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 426px) {
    width: auto;
  }
`;

const FooterTextItem = styled.p`
  font-size: 1rem;
  margin: 0 10px 0 10px;
  padding: 0 5px;
  text-align: center;

  @media screen and (max-width: 800px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 426px) {
    font-size: 0.8rem;
  }
`;

const CompanyNameContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  border-top: 1px solid black;

  @media screen and (max-width: 426px) {
    height: 60px;
  }
`;

const CompanyName = styled.h1`
  font-size: 1rem;
  align-self: end;

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 426px) {
    font-size: 0.8rem;
  }
`;

const Policy = styled(Link)`
  align-self: center;
  display: flex;
  flex-direction: column;
  text-decoration: underline;

  @media screen and (max-width: 800px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 426px) {
    font-size: 0.8rem;
  }
`;
