const addons = [
    {
      name: "Grafisk profil",
      text: "Grafisk profil för företag är en avgörande tjänst för att skapa en stark och igenkännbar identitet. Vi skräddarsyr grafiska profiler som förmedlar ditt företags värden och mål på ett visuellt tilltalande sätt. Från logotyper och färgscheman till typografi och bildstil, vår expertis sträcker sig över alla aspekter för att säkerställa en enhetlig och minnesvärd närvaro."
    },
    {
      name: "Content writing",
      text: "Vi skapar engagerande och strategiskt utformat innehåll. Genom att kombinera kreativitet och marknadsföringsinsikter arbetar vi för att berätta din unika historia och övertyga besökarna att engagera sig. Från SEO-optimerat innehåll till övertygande copywriting strävar vi efter att skapa en meningsfull anslutning mellan ditt varumärke och din målgrupp."
    },
    {
      name: "Administration",
      text: "Våra administrationstjänster sträcker sig bortom leveransfasen för att säkerställa din webbplats långsiktiga prestanda. Vi tar hand om underhåll och uppdateringar, så du kan fokusera på din verksamhet."
    }
  ];

  export default addons;