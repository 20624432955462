import React from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function CookieConsent() {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <CookieBanner>
        <h1><strong>Vi använder cookies för att förbättra din webbupplevelse</strong></h1>
      <p>
      Den här webbplatsen använder cookies för att säkerställa att du får den bästa upplevelsen på vår webbplats. Cookies används för att anpassa innehåll, tillhandahålla sociala mediefunktioner och analysera vår trafik. Genom att fortsätta använda vår webbplats samtycker du till vår användning av cookies. Läs vår <Link to={"/cookiepolicy"}>Cookie Policy</Link> för att få reda på mer.
      </p>
      <AcceptButton onClick={giveCookieConsent}>Accept</AcceptButton>
    </CookieBanner>
  );
}

const CookieBanner = styled.div`
  width: 50%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8f8f8;
  padding: 30px;
  text-align: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  a{
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    width: 70%;
  }

  @media screen and (max-width: 426px) {
    width: 90%;
    font-size: 0.8rem;
  }
`;

const AcceptButton = styled.button`
  background-color: rgba(187, 170, 211, 0.8);
  color: #fff;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(187, 170, 211, 1) ;
  }

  @media screen and (max-width: 426px) {
    font-size: 14px;
    margin-top: 10px;
  }
`;
