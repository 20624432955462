import React from "react";
import styled from "styled-components";

const Webdesign = () => {
  return (
    <ServicesWrapper>
      <CategoryContainer>
        <CategoryHeader>Web Design</CategoryHeader>
        <SubHeader>UX (Användarupplevelse)</SubHeader>
        <Description>
          Vårt engagemang för enastående UX-design sträcker sig bortom estetik.
          Vi fördjupar oss i förståelsen för din målgrupp, deras beteenden och
          preferenser. Genom noggrann användarforskning identifierar vi
          smärtpunkter och områden för förbättring, vilket säkerställer att
          varje interaktion med din webbplats är sömlös och meningsfull.
        </Description>

        <h2>Nyckelfunktioner:</h2>

        <p>
          Användarcentrerad design: Genom att placera dina användare i centrum
          skapar vi upplevelser som möter deras behov och gör din webbplats till
          en ovärderlig resurs för dem.
        </p>
        <p>
          Intuitiv navigation: Vi prioriterar tydlig och logisk navigering,
          vilket gör det enkelt för besökare att hitta den information eller de
          tjänster de söker.
        </p>
        <p>
          Tillgänglighet: Inkludering är viktigt. Våra designer följer
          tillgänglighetsstandarder för att säkerställa att alla, oavsett
          förmåga, kan interagera med din digitala närvaro.
        </p>
        <SubHeader>UI (Användargränssnitt)</SubHeader>
        <Description>
          Vid korsningen av konst och funktionalitet förvandlar vår UI-design
          koncept till fängslande visuella upplevelser. Varje element väljs
          noggrant för att inte bara representera ditt varumärke utan också
          engagera och lämna ett varaktigt intryck på din publik.
        </Description>

        <h2>Nyckelfunktioner:</h2>

        <p>
          Varumärkeskonsistens: Vi harmoniserar dina varumärkeselement över
          gränssnittet, förstärker varumärkesidentiteten och främjar
          varumärkesminnet.
        </p>
        <p>
          Visuell hierarki: Genom strategisk placering och betoning guidar vi
          användare genom gränssnittet, vilket säkerställer att de absorberar
          den viktigaste informationen.
        </p>
        <p>
          Responsiv design: I en era av olika enheter designar vi gränssnitt som
          sömlöst anpassar sig till olika skärmstorlekar, vilket garanterar en
          konsekvent och njutbar upplevelse över plattformar.
        </p>
        <p>
          Interaktiva element: Från subtila animationer till interaktiva
          komponenter sträcker sig våra designer bortom statiska visuella
          element och skapar dynamiska och engagerande användargränssnitt.
        </p>

        <h2>
          Genom att fläta samman UX- och UI-principer strävar vi efter att skapa
          en digital miljö där användare inte bara utför uppgifter effektivt
          utan också njuter av resan, vilket främjar en positiv uppfattning av
          ditt varumärke.
        </h2>
      </CategoryContainer>

      <CategoryContainer>
        <CategoryHeader>Web Development</CategoryHeader>
        <SubHeader>Technologies</SubHeader>
        <Description>
          Leveraging the latest technologies, we build robust and scalable web
          applications tailored to meet your specific business requirements.
        </Description>
        <SubHeader>Responsive Design</SubHeader>
        <Description>
          Our development process ensures that your website is fully responsive,
          providing a seamless experience across various devices and screen
          sizes.
        </Description>
        {/* Add more points about web development */}
      </CategoryContainer>

      <CategoryContainer>
        <CategoryHeader>Content Writing</CategoryHeader>
        <SubHeader>SEO (Search Engine Optimization)</SubHeader>
        <Description>
          Our content is crafted with SEO in mind, helping your website rank
          higher on search engines and reach a wider audience.
        </Description>
        <SubHeader>Copywriting</SubHeader>
        <Description>
          Engage your audience with compelling and persuasive copy that
          effectively communicates your brand message and drives conversions.
        </Description>
        {/* Add more points about content writing */}
      </CategoryContainer>

      <ContactCTA>
        Ready to enhance your online presence?{" "}
        <ContactLink href="/contact">Contact us</ContactLink>
      </ContactCTA>
    </ServicesWrapper>
  );
};

const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  padding-top: 200px;
  background: linear-gradient(
    3deg,
    rgba(187, 170, 211, 1) 0%,
    rgba(148, 208, 233, 1) 100%
  );
`;

const CategoryContainer = styled.div`
  max-width: 800px;
  width: 100%;
  margin-bottom: 40px;
`;

const CategoryHeader = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
`;

const SubHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
`;

const ContactCTA = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin-top: 40px;
`;

const ContactLink = styled.a`
  color: #007bff;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export default Webdesign;
