import React from "react";
import styled from "styled-components";

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Content>
        <Title>Integritetspolicy</Title>
        <Paragraph>
          <strong>Studio Meridian</strong> är personuppgiftsansvarig för
          behandlingen av personuppgifter som beskrivs i denna
          integritetspolicy. I denna integritetspolicy förklarar vi vilken typ
          av personuppgifter vi lagrar och hur vi behandlar dem. Denna
          integritetspolicy gäller för:{" "}
          <Link
            href="https://studiomeridian.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            studiomeridian.se
          </Link>{" "}
        </Paragraph>

        <Title>Personuppgifter som vi samlar in och behandlar</Title>
        <Paragraph>
          Vi behandlar följande kategorier av personuppgifter:
        </Paragraph>
        <ul>
          <li><strong>Grundläggande information:</strong> namn</li>
          <li><strong>Kontaktinformation:</strong> telefonnummer, e-postadress</li>
          {/* <li>Konto- och profilinformation: inställningar, preferenser</li> */}
          {/* <li>Betalningsinformation: Transaktionsinformation, betalningsmedel</li> */}
          {/* <li>Kundhistorik och kundengagemang: beställnings- och leveransinformation, kundvagnsrörelser, rabattkoder, lojalitetsprograminformation</li> */}
          {/* <li>Aktiva produkter och avtal samt produkter och tjänster som du tidigare har haft, hur mycket och hur ofta de används, status för produkter/tjänster</li> */}
          {/* <li>Kundaktivitet: läs- och handlingshistorik från appen, webbplatser eller elektronisk kommunikation vi skickar ut. Samt teknisk information om enheterna du använder</li> */}
          <li>
          <strong>Cookies:</strong> se vår information om cookies här:{" "}
            <Link
              href="http://localhost:3000/cookiepolicy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Cookie-policy
            </Link>
          </li>
        </ul>

        <Paragraph>
          Personuppgifterna samlas vanligtvis in direkt från dig eller genereras
          i samband med att du använder våra tjänster och produkter.
        </Paragraph>

        <Title>Hur vi använder personuppgifter</Title>
        <Paragraph>
          <strong>Leverans av tjänst/avtal</strong>
        </Paragraph>
        <Paragraph>
          Vi använder dina personuppgifter för att uppfylla våra avtal med dig,
          det vill säga när du har beställt en produkt eller tjänst från oss.
          Det rättsliga grunden för att behandla personuppgifter för detta
          ändamål är att behandlingen är nödvändig för att uppfylla ett avtal
          med dig.
        </Paragraph>

        <Paragraph>
          <strong>Hantering av kundrelationer</strong>
        </Paragraph>
        <Paragraph>
          Vi använder dina personuppgifter för att hantera vår kundrelation med
          dig. Det kan till exempel handla om kundtjänst, hantering av klagomål
          och felsökning gällande ditt konto. Den rättsliga grunden för att
          behandla personuppgifter för detta ändamål är att behandlingen är
          nödvändig för att uppfylla ett avtal med dig.
        </Paragraph>

        <Paragraph>
          <strong>Analys, affärsutveckling och förbättring av tjänster</strong>
        </Paragraph>
        <Paragraph>
          Vi arbetar kontinuerligt med att utveckla och förbättra våra tjänster
          och produkter. En stor del av detta arbete innebär att analysera olika
          former av personuppgifter, till exempel kundaktivitet, kundhistorik
          och konto- och profilinformation. Den rättsliga grunden för att
          behandla personuppgifter för detta ändamål är vårt berättigade
          intresse.
        </Paragraph>

        <Paragraph>
          <strong>Anpassad användarupplevelse</strong>
        </Paragraph>
        <Paragraph>
          Vi anpassar användarupplevelsen och kommunikationen till din
          kundrelation och vi använder personuppgifter för detta ändamål. Den
          rättsliga grunden för att behandla personuppgifter för detta ändamål
          är vårt berättigade intresse.
        </Paragraph>

        <Paragraph>
          <strong> Försäljning och marknadsföring</strong>
        </Paragraph>
        <Paragraph>
          Vi använder personuppgifter i samband med försäljning och
          marknadsföring av våra produkter och tjänster, till exempel genom att
          du får e-post från oss. Den rättsliga grunden för att behandla
          personuppgifter för detta ändamål är vårt berättigade intresse. Du har
          möjlighet att reservera dig från delar av denna behandling genom att
          till exempel reservera dig från att få e-post från oss. Utöver detta
          kan vi också be dig om samtycke att använda dina personuppgifter för
          så kallad profilering, där vi drar slutsatser om dina intressen och
          behov utifrån dina personuppgifter. Syftet med profileringen är att
          vår marknadsföring ska bli mer relevant.
        </Paragraph>

        <Paragraph>
          <strong>Systemövervakning, felsökning m.m.</strong>
        </Paragraph>
        <Paragraph>
          Vi övervakar våra system för fel och problem. En del av dessa
          processer innebär lagring och behandling av personuppgifter. Den
          rättsliga grunden för att behandla personuppgifter för detta ändamål
          är vårt berättigade intresse.
        </Paragraph>

        <Paragraph>
          <strong>
            Säkerhet, avslöjande av bedrägeri och kriminell verksamhet
          </strong>
        </Paragraph>
        <Paragraph>
          Vi behandlar personuppgifter i vårt arbete med att skydda våra
          användare och oss själva mot bedrägeri, missbruk och kriminell
          verksamhet. Den rättsliga grunden för att behandla personuppgifter för
          detta ändamål är vårt berättigade intresse.
        </Paragraph>

        <Paragraph>
          <strong>Följa rättsliga åtaganden</strong>
        </Paragraph>
        <Paragraph>
          I vissa fall är vi skyldiga att behandla personuppgifter av hänsyn
          till andra rättsliga åtaganden. Ett exempel på detta är information
          som är kopplad till försäljning, som vi är skyldiga att bokföra och
          spara enligt bokföringslagen. Det rättsliga grunden för att behandla
          personuppgifter för detta ändamål är att behandlingen är nödvändig för
          att uppfylla ett rättsligt åtagande som åligger oss.
        </Paragraph>

        <Title>Dina rättigheter</Title>
        <Paragraph>
          Om du vill utöva någon av dina rättigheter, kontakta oss på{" "}
          <Link href="<DPO e-postadress>">&lt;DPO e-postadress&gt;</Link>
        </Paragraph>

        <Paragraph>
          <strong>Rätt till insyn i egna uppgifter</strong>
        </Paragraph>
        <Paragraph>
          Du kan begära en kopia av alla uppgifter vi behandlar om dig. Kontakta
          e-postadressen ovan för att utnyttja din insynsrätt.
        </Paragraph>

        <Paragraph>
          <strong>Rätt till rättelse av personuppgifter</strong>
        </Paragraph>
        <Paragraph>
          Du har rätt att begära att vi rättar eller kompletterar uppgifter som
          är felaktiga eller vilseledande.
        </Paragraph>

        <Paragraph>
          <strong>Rätt till radering av personuppgifter</strong>
        </Paragraph>
        <Paragraph>
          Du har rätt att få dina personuppgifter raderade utan dröjsmål. Du kan
          när som helst begära att vi raderar uppgifter om dig själv. Men notera
          att information som vi är skyldiga att behålla av hänsyn till andra
          rättsliga åtaganden (som till exempel bokföringslagen) inte kommer att
          raderas.
        </Paragraph>

        <Paragraph>
          <strong>Begränsning av behandling av personuppgifter</strong>
        </Paragraph>
        <Paragraph>
          I vissa situationer kan du också begära att vi begränsar behandlingen
          av uppgifter om dig. Du gör detta genom att administrera samtycken
          eller reservationer i våra lösningar.
        </Paragraph>

        <Paragraph>
          <strong>Protestera mot behandling av personuppgifter</strong>
        </Paragraph>
        <Paragraph>
          Om vi behandlar uppgifter om dig med grund i våra uppgifter eller på
          grundval av en intresseavvägning har du rätt att protestera mot vår
          behandling av uppgifter om dig. Du gör detta genom att administrera
          samtycken eller reservationer i våra lösningar.
        </Paragraph>

        <Paragraph>
          <strong>Dataportabilitet</strong>
        </Paragraph>
        <Paragraph>
          Du har rätt att få ut dina personuppgifter i ett strukturerat, allmänt
          använt och maskinläsbart format. Kontakta e-postadressen ovan för att
          få ut dina personuppgifter.
        </Paragraph>

        <Paragraph>
          <strong>Du kan klaga på vår behandling av personuppgifter</strong>
        </Paragraph>
        <Paragraph>
          Vi hoppas att du informerar oss om du anser att vi inte följer
          reglerna i personuppgiftslagen. Säg gärna till först genom den
          kontakten eller kanalen du redan har etablerat med oss. Du kan också
          klaga på vår behandling av personuppgifter. Du gör detta till
          Datatilsynet.
        </Paragraph>

        <Paragraph>
          Denna integritetspolicy är baserad på en mall för integritetspolicy
          från gdprcontrol.se
        </Paragraph>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  background: linear-gradient(
    3deg,
    rgba(187, 170, 211, 1) 0%,
    rgba(148, 208, 233, 0.5) 100%
  );
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 70%;
  height: auto;
  background-color: rgb(248, 237, 227, 0.5);
  margin: 200px 0 100px;
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
`;

export default PrivacyPolicy;
