import React from "react";
import styled from "styled-components";

const AboutUs = () => {
  return (
    <AboutUsWrapper>
      <AboutUsContent>
        <HeaderText>Välkommen på min resa</HeaderText>
        <Paragraph>
          Stella heter jag och är bakom Studio Meridian &#x1F44B; Med en utbildning
          inom utveckling och en passion för allt inom Design har jag funnit en
          kärlek till att skapa vackra och meningsfulla digitala upplevelser med
          hjälp av kod. Driven av denna passion kände jag att det var dags att ta
          min kompetens till nästa nivå och starta denna resa.
        </Paragraph>
        <Paragraph>
          Min vision är att erbjuda innovativa och skräddarsydda webblösningar
          som inte bara uppfyller våra kunders behov utan överträffar deras
          förväntningar. Jag tror på kraften hos ett starkt visuellt intryck och
          strävar efter att skapa webbplatser som inte bara är funktionella utan
          också estetiskt tilltalande.
        </Paragraph>
        <Paragraph>
          Välkommen till Studio Meridian, där kreativitet möter kodning och
          där varje projekt är en möjlighet att skapa något extraordinärt.
        </Paragraph>

        {/* <HeaderText>Vårt Team</HeaderText>
        <TeamMembers>
          <TeamMember>
            <TeamMemberImage src="john-doe.jpg" alt="John Doe" />
            <TeamMemberInfo>
              <TeamMemberName>John Doe</TeamMemberName>
              <TeamMemberRole>VD och Grundare</TeamMemberRole>
            </TeamMemberInfo>
          </TeamMember>

          <TeamMember>
            <TeamMemberImage src="jane-doe.jpg" alt="Jane Doe" />
            <TeamMemberInfo>
              <TeamMemberName>Jane Doe</TeamMemberName>
              <TeamMemberRole>Designchef</TeamMemberRole>
            </TeamMemberInfo>
          </TeamMember>
        </TeamMembers> */}

        {/* <ContactInfo>
          <HeaderText>Kontakta Oss</HeaderText>
          <p>Email: info@exempel.se</p>
          <p>Telefon: 123-456-789</p>
        </ContactInfo> */}
      </AboutUsContent>
    </AboutUsWrapper>
  );
};

const AboutUsWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    3deg,
    rgba(187, 170, 211, 1) 0%,
    rgba(148, 208, 233, 0.6) 80%
  );
`;

const AboutUsContent = styled.div`
  width: 60%;
  padding: 40px;
  margin-top: 200px;
  margin-bottom: 200px;
  text-align: center;
  background-color: #fff;
  background-color: rgba(137, 138, 166, 0.4);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  @media (max-width: 1440px) {
  width: 60%;
  padding: 20px;
  }

  @media (max-width: 768px) {
  width: 80%;
  margin-top: 320px;
  }

  @media (max-width: 426px) {
  width: 90%;
  }

  @media (max-width: 376px) {
    margin-top: 460px;
  }
`;

// color: #333;
const HeaderText = styled.h2`
  margin-bottom: 20px;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 426px) {
    font-size: 1rem;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 426px) {
    font-size: 0.8rem;
  }
  `;
  // color: #555;

// const TeamMembers = styled.div`
//   display: flex;
//   justify-content: space-around;
//   margin-bottom: 40px;
// `;

// const TeamMember = styled.div`
//   max-width: 200px;
//   text-align: center;
// `;

// const TeamMemberImage = styled.img`
//   width: 100%;
//   border-radius: 50%;
// `;

// const TeamMemberInfo = styled.div`
//   margin-top: 10px;
// `;

// const TeamMemberName = styled.p`
//   font-weight: bold;
//   margin-bottom: 5px;
// `;

// const TeamMemberRole = styled.p`
//   color: #777;
// `;

// const ContactInfo = styled.div`
//   margin-top: 40px;
// `;

export default AboutUs;
