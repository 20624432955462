import React from "react";
import styled from "styled-components";
import categories from "../data/serviceCard.js";
import addons from "../data/addons.js";
import { packagePrices } from "../data/packages.js";
import Design from "../assets/images/design.jpg";
import Tick from "../assets/icons/checkmark.svg";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <Wrapper>
      <DescriptionContainer>
        <DescriptionContent>
          <HeaderTitle>
            Studio Meridian: Din Partner för Digitala Framgångar
          </HeaderTitle>
          <HeaderText>
            Vi erbjuder skräddarsydda lösningar inom webbdesign, webbutveckling,
            content writing och administration. Vårt fokus är att skapa
            användarvänliga webbplatser, tekniskt robusta lösningar och
            engagerande innehåll. Oavsett om du behöver förnyelse av din
            webbplats, teknisk utveckling, skräddarsydda texter eller
            administrativa effektiviseringar, är vi här för att hjälpa dig.
          </HeaderText>
        </DescriptionContent>
        <Image src={Design} alt="computer screen" />
      </DescriptionContainer>

      {/* <Title>Våra Tjänster</Title> */}

      <CardsContainer>
        <CardsWrapper>
          {categories.map((category) => (
            <CategoryCard
              key={category.name}
              to={category.path}
              color={category.color}
            >
              <CategoryName>{category.name}</CategoryName>
              <CategoryDesc>{category.text}</CategoryDesc>
            </CategoryCard>
          ))}
        </CardsWrapper>
      </CardsContainer>

      <Title>Tilläggstjänster</Title>

      <CardsContainer>
        <CardsWrapper>
          {addons.map((addon) => (
            <CategoryCard key={addon.name}>
              <CategoryName>{addon.name}</CategoryName>
              <CategoryDesc>{addon.text}</CategoryDesc>
            </CategoryCard>
          ))}
        </CardsWrapper>
      </CardsContainer>

      <PaketPriserWrapper>
        <TitleHolder>
          <PaketPriserTitle>Paket och Priser</PaketPriserTitle>
        </TitleHolder>
        <PaketPriserContainer>
          <PaketPriser>
            {packagePrices.map((item) => (
              <PriceCard key={item.title}>
                <PriceCardSectionOne>
                  <PackageName>{item.title}</PackageName>
                  <PackageDescription>{item.description}</PackageDescription>
                </PriceCardSectionOne>
                <PriceCardSectionTwo>
                  {item.features.map((feature, index) => (
                    <p key={index}>
                      {" "}
                      <img src={Tick} alt="check mark" />
                      {feature}
                    </p>
                  ))}
                </PriceCardSectionTwo>
                <PriceContainer>
                  <Price>fr {item.price};-</Price>
                </PriceContainer>
              </PriceCard>
            ))}
          </PaketPriser>
        </PaketPriserContainer>
      </PaketPriserWrapper>
      <CTAButtonContainer>
        <CTAText>Söker du professionell vägledning för att fastställa dina behov? Tveka inte att höra av dig till oss, så återkommer vi alltid inom 24h!</CTAText>
        <Link to="/contact">
        <CTAButton>Kontakta oss</CTAButton>
        </Link>
      </CTAButtonContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 200px 0;
  background: linear-gradient(
    3deg,
    rgba(187, 170, 211, 1) 0%,
    rgba(148, 208, 233, 0.6) 80%
  );
`;

const DescriptionContainer = styled.div`
  width: 80%;
  height: 350px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 50px;

  @media (max-width: 1025px) {
    width: 90%;
  }

  @media (max-width: 426px) {
    width: 100%;
  }
`;

const DescriptionContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(137, 138, 166, 0.4);
  padding: 30px;

  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 426px) {
    width: 90%;
  }
  @media (max-width: 321px) {
    width: 100%;
    padding: 10px;
  }
`;

const Image = styled.img`
  width: 50%;

  @media (max-width: 769px) {
    display: none;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 1.5rem;
  font-family: "Sora", sans-serif;

  @media (max-width: 810px) {
    font-size: 1.3rem;
  }
  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
`;

const HeaderText = styled.p`
  font-size: 1rem;
  padding-top: 10px;

  @media (max-width: 500px) {
    font-size: 0.9rem;
  }
`;

const CardsContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 626px) {
    width: 100%;
  }
`;

const CardsWrapper = styled.div`
  width: 80%;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1210px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CategoryCard = styled.div`
  width: 31%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  color: #fff;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  background-color: rgba(137, 138, 166, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;

  &:hover {
    background-color: #ac8daf;
    transform: translateY(-10px);
  }

  @media (max-width: 1210px) {
    width: 30%;
  }
  @media (max-width: 1024px) {
    height: 400px;
  }
  @media (max-width: 768px) {
    width: 46%;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 260px;
  }
  @media (max-width: 321px) {
    height: 270px;
  }
`;

const CategoryName = styled.h1`
  font-size: 1.2rem;
  border-bottom: 1px solid white;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 20px;
  font-family: "Sora", sans-serif;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    height: 10%;
    padding-bottom: 10px;
  }

  @media (max-width: 500px) {
    height: 20%;
  }
`;

const CategoryDesc = styled.h1`
  font-size: 1rem;
  padding-top: 20px;
  height: 80%;

  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }
`;

const Title = styled.h1`
  font-family: "Sora", sans-serif;
  font-size: 1.4rem;
  padding: 30px 25px;
  color: white;
  font-weight: 600;
`;

// Paket och Priser section

const PaketPriserWrapper = styled.div`
  margin-top: 100px;
  width: 80%;
  background-color: rgba(137, 138, 166, 0.4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;

  @media (max-width: 1024px) {
    width: 95%;
  }

  @media (max-width: 800px) {
    padding: 40px 0;
  }
`;

const TitleHolder = styled.div`
  width: 93.5%;
  display: flex;
  margin-bottom: 20px;
`;

const PaketPriserTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Sora", sans-serif;
`;

const PaketPriserContainer = styled.div`
  width: 100%;
  display: flex;
`;

const PaketPriser = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;

  @media (max-width: 651px) {
    flex-direction: column;
  }
`;

const PriceCard = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 651px) {
    width: 80%;
    padding-left: 20px;
  }
  @media (max-width: 426px) {
    width: 100%;
    padding: 10px;
  }
`;

const PriceCardSectionOne = styled.div``;

const PackageName = styled.h1`
  font-family: "Sora", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 0;
  font-weight: 600;

  @media (max-width: 800px) {
    font-size: 1rem;
  }
`;

const PackageDescription = styled.p`
  font-size: 1.1rem;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    font-size: 1rem;
  }
`;

const PriceContainer = styled.div`
  padding: 20px 0;

  @media (max-width: 651px) {
    margin-bottom: 40px;
  }
`;

const Price = styled.h2`
  font-family: "Sora", sans-serif;
  font-weight: 600;
`;

const PriceCardSectionTwo = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 20px;
    margin-right: 10px;
  }

  p {
    display: flex;
    flex-direction: row;
  }
`;

// call to action button

const CTAButtonContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
`;

const CTAText = styled.p`
  width: 60%;
  font-size: 1.2rem;
  font-family: 'Sora', sans-serif;

  @media (max-width: 1440px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 80%;
    font-size: 1rem;
  }
  @media (max-width: 426px) {
    width: 100%;
    font-size: 0.9rem;
  }
  
`;

const CTAButton = styled.button`
  width: 8em;
  height: 3.5rem;
  border-radius: 10px;
  font-size: 1.2rem;
  color: black;
  background-color: #9dcadd;
  transition: color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Sora", sans-serif;
  font-weight: 300;
  margin-top: 30px;

  svg {
    fill: #453f3d;
    width: 24px;
    padding: 0 5px;
    margin-top: 4px;
    transition: fill 0.3s ease;
  }

  &:hover {
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  @media screen and (max-width: 426px) {
    font-size: 1rem;
  }
`;





export default Services;
