import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
// import Welcome from "./views/welcome.jsx";
import Home from "./views/home.jsx";
import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
import Services from './views/services.jsx';
import Projects from './views/projects.jsx';
import About from './views/about.jsx';
import Contact from './views/contact.jsx';
import Success from './views/success.jsx';
import Integritetspolicy from './views/integpolicy.jsx';
import Cookiepolicy from './views/cookies.jsx';
import Webdesign from './views/services/design.jsx';
import CookieConsent from "./components/cookie.jsx";
import { useCookies } from "react-cookie";

function App() {
  const [cookies] = useCookies(["cookieConsent"]);
  return (
    <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/success" element={<Success />} />
      <Route path="/webdesign" element={<Webdesign />} />
      <Route path="/integritetspolicy" element={<Integritetspolicy />} />
      <Route path="/cookiepolicy" element={<Cookiepolicy />} />
      {/* <Route path="/webdevelopment" element={<Webdevelopment />} />
      <Route path="/content-writing" element={<Contentwriting />} />
      <Route path="/administration" element={<Administration />} /> */}
    </Routes>
    <Footer />
    {!cookies.cookieConsent && <CookieConsent/>}
  </Router>
  );
}

export default App;
