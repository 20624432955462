import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as RightArrow } from "../assets/icons/right-arrow-svg.svg";
import Services from "../components/services";
import FAQItem from "../components/qna";
import faqData from "../data/FAQ";

const Home = () => {
  return (
    <HomeWrapper>
      <LandingSection>
        <Content>
          <Phrase>
            Välkommen till Studio Meridian, Tillsammans gör vi din digitala
            närvaro magisk med stilren webbdesign och smart webbutveckling
          </Phrase>
          <Link to="/contact">
            <ContactBtn>
              Begär offert <RightArrow />
            </ContactBtn>
          </Link>
        </Content>
      </LandingSection>
      <Services />
      <QNAContainer>
      <QuestionsTitle>Frågor & Svar</QuestionsTitle>
        {faqData.map((item) => (
          <FAQItem
            key={item.id}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </QNAContainer>
    </HomeWrapper>
  );
};

const HomeWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const LandingSection = styled.div`
  background: linear-gradient(
    3deg,
    rgba(187, 170, 211, 1) 0%,
    rgba(148, 208, 233, 1) 100%
  );
  width: 100%;
  height: auto;
  padding-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 100px;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    width: 80%;
  }

  @media screen and (max-width: 426px) {
    width: 90%;
    height: 60vh;
    padding-bottom: 10px;
  }
`;

const Phrase = styled.p`
  color: #453f3d;
  width: 70%;
  height: auto;
  font-size: 2.1rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 20px;
  position: relative;
  font-family: "Sora", sans-serif;
  font-weight: 500;

  @media screen and (max-width: 1024px) {
    width: 80%;
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 1.8rem;
  }

  @media screen and (max-width: 426px) {
    width: 100%;
    height: auto;
    font-size: 1.3rem;
    padding: 10px 20px;
  }
`;

const ContactBtn = styled.button`
  width: 8em;
  height: 3.5rem;
  border-radius: 10px;
  font-size: 1.5rem;
  color: #453f3d;
  background-color: #9dcadd;
  transition: color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Sora", sans-serif;
  font-weight: 300;

  svg {
    fill: #453f3d;
    width: 24px;
    padding: 0 5px;
    margin-top: 4px;
    transition: fill 0.3s ease;
  }

  &:hover {
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  @media screen and (max-width: 426px) {
    font-size: 1.3rem;
  }
`;

const QuestionsTitle = styled.h1`
  font-size: 1.5rem;
  font-family: 'Sora', sans-serif;
  text-align: center;
  padding: 20px 0;
`
const QNAContainer = styled.div`
background: linear-gradient(
  3deg,
  rgba(187, 170, 211, 1) 0%,
  rgba(148, 208, 233, 1) 100%
);
padding: 50px 0;
`

export default Home;
