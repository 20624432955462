import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo/logo_cropped.png'

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <HeaderContainer scrolled={scrolled}>
      <HeaderLogo to="/"><img src={Logo} alt="Logo of Studio Meridian" /></HeaderLogo>
        <MobileMenuButton showMenu={showMenu} onClick={toggleMenu} />
      {showMenu && (
        <MobileMenu>
          <HeaderLink to="/services" onClick={toggleMenu}>TJÄNSTER</HeaderLink>
          {/* <HeaderLink to="/projects" onClick={toggleMenu}>PROJEKT</HeaderLink> */}
          <HeaderLink to="/about" onClick={toggleMenu}>OM OSS</HeaderLink>
          <HeaderLink to="/contact" onClick={toggleMenu}>KONTAKT</HeaderLink>
        </MobileMenu>
      )}
      <LinkContainer>
        <HeaderLink to="/services">TJÄNSTER</HeaderLink>
        {/* <HeaderLink to="/projects">PROJEKT</HeaderLink> */}
        <HeaderLink to="/about">OM OSS</HeaderLink>
        <HeaderLink to="/contact">KONTAKT</HeaderLink>
      </LinkContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  height: 120px;
  padding: 20px 30px;
  transition: background-color 0.2s, color 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  color: #313341;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.scrolled &&
    `
    background-color: rgb(174,183,218);
    color: #5c5351;
  `}
`;

const HeaderLogo = styled(Link)`
  transition: color 0.3s;
  cursor: pointer;
  width: 200px;

  @media screen and (max-width: 800px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.6rem;
  }

`;

const LinkContainer = styled.div`
  display: flex;
  padding-top: 10px;

  @media screen and (max-width: 600px) {
    display: none; /* Hide links on small screens */
  }
`;

const HeaderLink = styled(Link)`
  font-size: 1.2rem;
  margin: 0 10px 0 10px;
  padding: 0 5px;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Transparent background on hover */
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.3); /* Transparent background on click */
  }

  @media screen and (max-width: 800px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1rem;
  }
`;

const MobileMenuButton = styled.div`
  font-size: 2rem;
  cursor: pointer;
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }

  &:before {
    content: '${(props) => (props.showMenu ? '\\2715' : '\\2630')}'; /* Unicode for cross and hamburger */
    color: #000;
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 83%;
  background-color: #F8EDE3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1001;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

export default Header;
